import { graphql, useStaticQuery } from 'gatsby'


function UseContentfulImage(assetUrl) {
    
   /*const asset = useStaticQuery(
        graphql`
          query{
            contentfulAsset( contentful_id: { eq: ${ assetUrl }}){
              file{
                url
              }
              title
            }
          }
        `
    )*/
    const assets = useStaticQuery(
        graphql`
        query{
            allContentfulAsset{
              edges{
                node{
                  title
                  file{
                      url
                  }
                  contentful_id
                }
              }
            }
          }
        `
    )
    const asset = assets.allContentfulAsset.edges.find(({ node }) => node.contentful_id === assetUrl)
    return asset
}

export default UseContentfulImage
