import React from 'react'
import Log from './Log'

function RelatedLogs(props) {
    return (
        <section className="grey">
            <h2>PROJECT LOGS</h2>
            <Log className="log" data={ props.data }/>
        </section>
    )
}

export default RelatedLogs
