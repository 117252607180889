import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import UseContentfulImage from '../components/UseContentfulImage'
import RelatedLogs from '../components/RelatedLogs'
import Arrow from '../components/Arrow'


export const query = graphql`
    query($slug: String!) {
        contentfulProject(slug: {eq: $slug}){
            title
            slug
            screenshot{
                file{
                    url
                }
            }
            liveWebsite
            content{
                raw
            }
            logs{
                title
                slug
                publishedDate(formatString: "MM/DD/YYYY")
            }
        }
    }
`

function ProjectTemplate(props) {
    const content = JSON.parse(props.data.contentfulProject.content.raw);
    //console.log(content);

    let link;
    const linkData = props.data.contentfulProject.liveWebsite
    if(linkData !== ''){
        link = <a className="live-website" href={ linkData }>VISIT LIVE WEBSITE <Arrow direction="right"/></a>;
    }

    const screenshotData = props.data.contentfulProject.screenshot.file.url;
    let screenshot;
    if(screenshotData !== ''){
        screenshot = <img className="project-screenshot" src={ screenshotData } alt=""/>
    }

    const options = {
        renderNode: {
            "embedded-asset-block": (node) => {
                //console.log(node)
                // render the EMBEDDED_ASSET as you need
                const asset = UseContentfulImage(node.data.target.sys.id)
                //console.log(asset.node.title)
                
                if (asset) {
                    const url = asset.node.file.url
                    const alt = asset.node.title
                    return (
                        <img src={ url } alt={ alt } />
                    )
                }
            }
        }
    }
    //console.log(props.data.contentfulProject.logs);
    let related = '';
    if(props.data.contentfulProject.logs !==  null){
        related = <RelatedLogs data={ props.data.contentfulProject.logs }/>
    }
    return (
        <Layout class="blue">
            <section className="blue">
                <h1 className="upper">{ props.data.contentfulProject.title }</h1>
            </section>
            <section>
                { screenshot }
                { link }
                <div className="project-content">
                    { documentToReactComponents(content, options) }
                </div>
            </section>
            { related }
        </Layout>
    )

}

export default ProjectTemplate
