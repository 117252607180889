import { Link } from 'gatsby';
import React from 'react'

function Log(props) {

    return (
        <ul className={ props.className }>
            { props.data.map((log)=>{
                if( log.node !== undefined ){
                    return (
                        <li key={ log.node.slug }>
                            <Link to={ '/logs/' + log.node.slug }>
                                { log.node.title }
                                <span>{ log.node.publishedDate}</span>
                            </Link>
                        </li>
                    );
                }else{
                    return (
                        <li key={ log.slug }>
                            <Link to={ '/logs/' + log.slug }>
                                { log.title }
                                <span>{ log.publishedDate}</span>
                            </Link>
                        </li>
                    );
                }
            }) }
        </ul>
    )
}

export default Log
